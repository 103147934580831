import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animTypes, animClass } from '../AnimatedBlock';
import ContentWrap from '../ContentWrap';
import SectionTitle from '../ui/SectionTitle';
import classNames from 'classnames';
import IconLinkArrow from '../icons/IconLinkArrow';

import { scrollbars, breakpoints, p2r } from '../../styles';
import GalleryThumb from '../ui/GalleryThumb';

const style = css`
  margin-bottom: 120px;
  .image-gallery--top {
    .description {
      margin-bottom: 36px;
    }
  }

  .image-gallery--wrap {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    ${scrollbars.h};
  }

  .image-gallery--tray {
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
  }

  .image-gallery--mid {
    display: block;
    margin-bottom: 32px;
  }

  .image-gallery--main {
    display: block;
    position: relative;
    height: 600px;
    max-height: calc(100vh - 140px);
    width: 100%;
    background-color: rgba(0,0,0,0.01);
    > div {
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .image-gallery--info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .caption {
      font-size: ${p2r(12)};
      font-style: italic;
    }
    .counter-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      .counter {
        font-size: ${p2r(16)};
      }
      .controls {
        margin-left: 20px;
      }
      .ctrl-btn {
        display: inline-block;
        &.prev {
          margin-right: 12px;
          > svg {
            transform: rotate(180deg);
          }
        }
        &.next {
          margin-left: 12px;
        }
      }
    }
  }
 
  @media(max-width: ${breakpoints.mobile}px) {
    margin-bottom: 64px;
  }
`;

const ImageGalleryBlock = ({ content, items }) => {
  const { section_title, description, narrow_article_width } = content;
  const [ currentImage, setCurrentImage ] = useState(0);
  const [ imageData, setImageData ] = useState();

  const numImages = items.length;

  const clickNext = () => {
    let nxt = (currentImage + 1)%numImages;
    setCurrentImage(nxt);
  }

  const clickPrev = () => {
    let nxt = currentImage - 1;
    if (nxt < 0) nxt = numImages - 1;
    setCurrentImage(nxt);
  }

  useEffect(() => {
    const dat = items[currentImage];
    setImageData(dat);
  }, [currentImage, items, setImageData]);

  return (
    <AnimatedBlock css={style}>
      <ContentWrap narrow={narrow_article_width}>
        <div className='image-gallery--top'>
          { section_title && (
            <SectionTitle title={section_title} animDelay={0} />
          )}
          { description && (
            <div className={classNames('description', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.1} dangerouslySetInnerHTML={{__html:description.html}}/>
          )}
        </div>
        <div className='image-gallery--mid'>
          <div className='image-gallery--main'>
            { imageData && (
              <div style={{backgroundImage: `url(${imageData.full_image.url})`}} aria-label={imageData.full_image.alt} />
            )}
          </div>
          { imageData && (
            <div className='image-gallery--info'>
              <div className='caption'>{imageData.caption}</div>
              <div className='counter-controls'>
                <div className='counter'>{`${currentImage + 1}/${numImages}`}</div>
                <div className='controls'>
                  <button className={classNames('ctrl-btn', 'prev')} aria-label='previous' onClick={clickPrev}>
                    <IconLinkArrow />
                  </button>
                  <button className={classNames('ctrl-btn', 'next')} aria-label='next' onClick={clickNext}>
                    <IconLinkArrow />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='image-gallery--wrap'>
          <div className='image-gallery--tray'>
            { items.map((d,i) => {
              const del = i * 0.1;
              return (
                <GalleryThumb
                  {...d}
                  imageId={i}
                  isCurrent={currentImage === i}
                  setCurrentImage={setCurrentImage}
                  animDelay={del}
                  key={i}
                />
              )
            })}
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default ImageGalleryBlock;
